<template>
  <nav id="sidebar" class="navbar navbar-vertical fixed-left navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <!--toggle-->
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#sidebarCollapse"
        aria-controls="sidebarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <router-link :to="`/${$i18n.locale}/dashboard`" rel="home" class="navbar-brand">
        <img :src="require('@/assets/icons/bloq.svg')" alt="Bloqhouse logo" class="navbar-brand-img mx-auto" />
      </router-link>
      <!--user (mobile)-->
      <div class="navbar-user d-lg-none">
        <div class="dropdown">
          <!-- <a
            href="#!"
            id="sidebarIcon"
            class="dropdown-toggle"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div class="avatar avatar-sm avatar-online">
              <v-gravatar
                :email="userEmail"
                :alt="userName"
                default-img="identicon"
                class="avatar-img rounded-circle"
              />
            </div>
          </a> -->
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="sidebarIcon">
            <div class="dropdown-item">
              <router-link to="">
                {{ $t('settings.profile') }}
              </router-link>
            </div>
            <hr class="dropdown-divider" />
            <a href="#" role="button" class="dropdown-item" @click.prevent="logOut({ redirect: `/${lang}/login` })">
              {{ $t('common.logout') }}
            </a>
          </div>
        </div>
      </div>
      <div class="collapse navbar-collapse" id="sidebarCollapse">
        <!--links-->
        <ul class="navbar-nav">
          <li class="nav-item" v-if="!isManager && !isCallAgent">
            <router-link :to="`/${$i18n.locale}/dashboard`" class="nav-link" active-class="active">
              <font-awesome-icon :icon="['fa', 'tachometer-alt']" class="mr-3" fixed-width />
              {{ $t('dashboard.dashboard') }}
            </router-link>
          </li>
          <li class="nav-item" v-if="!isCallAgent">
            <router-link :to="`/${$i18n.locale}/assets`" class="nav-link" active-class="active">
              <font-awesome-icon :icon="['fa', 'building']" class="mr-3" fixed-width />
              {{ $tc('common.project', 2) }}
            </router-link>
          </li>
          <li class="nav-item" v-if="!isCallAgent">
            <router-link :to="`/${$i18n.locale}/investments/all`" class="nav-link" active-class="active">
              <font-awesome-icon :icon="['fa', 'money-check']" class="mr-3" fixed-width />
              {{ $tc('common.investment', 2) }}
            </router-link>
          </li>
          <li class="nav-item" v-if="!isManager">
            <router-link :to="`/${$i18n.locale}/users/all`" class="nav-link" active-class="active">
              <font-awesome-icon :icon="['fa', 'user']" class="mr-3" fixed-width />
              {{ $tc('common.user', 2) }}
            </router-link>
          </li>
          <li v-if="tradingBoard" class="nav-item">
            <router-link :to="`/${$i18n.locale}/trading-board/open`" class="nav-link" active-class="active">
              <font-awesome-icon :icon="['fa', 'chart-line']" class="mr-3" fixed-width />
              {{ $tc('projects.tradingBoard') }}
            </router-link>
          </li>
        </ul>
        <template v-if="!isManager && !isCallAgent">
          <hr class="my-4" />
          <h3 class="navbar-heading h6 text-muted">
            {{ $t('common.other') }}
          </h3>
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link :to="`/${$i18n.locale}/managers`" class="nav-link" active-class="active">
                <font-awesome-icon :icon="['fa', 'users']" class="mr-3" fixed-width />
                {{ $tc('common.teamMember', 2) }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="`/${$i18n.locale}/settings/general`" class="nav-link" active-class="active">
                <font-awesome-icon :icon="['fa', 'cog']" class="mr-3" fixed-width />
                {{ $t('common.settings') }}
              </router-link>
            </li>
            <!-- <li class="nav-item" v-if="showManagersMenuLink">
            <router-link to="/${$i18n.locale}/tracing"
                         class="nav-link"
                         active-class="active"
            >
              <font-awesome-icon :icon="['fa', 'search']"
                                 class="mr-3"
                                 fixed-width
              />
              Tracing
            </router-link>
          </li> -->
            <li class="nav-item nav-item-external">
              <a
                href="https://portal.prodpad.com/cc8a47a4-8449-11e8-81e9-0288f735e5b9"
                target="_blank"
                class="nav-link"
                rel="nofollow"
              >
                <font-awesome-icon :icon="['fa', 'comment']" class="mr-3" fixed-width />
                {{ $t('common.feedback') }}
                <font-awesome-icon :icon="['fa', 'external-link-alt']" size="xs" />
              </a>
            </li>
          </ul>
        </template>
        <div class="navbar-user d-flex my-4 my-lg-0 mt-lg-auto">
          <div>
            <a href="#" role="button" class="logout__icon" @click.prevent="logOut({ redirect: `/${lang}/login` })">
              {{ $t('common.logout') }}
              <font-awesome-icon :icon="['fa', 'sign-out-alt']" fixed-width />
            </a>
          </div>
          <div class="main-nav-lang">
            <LanguageSidebar />
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, State as StateClass } from 'vuex-class';
import { State } from '@/models/State';
import { ManagerRole } from '@/models/manager/Manager';
import { CurrentManager } from '@/models/manager/CurrentManager';
import Logo from '@/assets/icons/bloq.svg?inline';
import LanguageSidebar from '@/components/navigation/language-sidebar/LanguageSidebar.vue';
import { clientConfig } from '@/helpers/clientData';

// @ts-expect-error - Unknown types
@Component({
  components: {
    Logo,
    LanguageSidebar,
  },
})
export default class Sidebar extends Vue {
  lang = '';

  whitelabelConfig = clientConfig();
  tradingBoard = !!this.whitelabelConfig.functionality.tradingBoardVersion;

  @Getter getCurrentManager!: CurrentManager;
  @Getter getCurrentManagerRole!: ManagerRole;
  @Getter getManagerProfileData!: Record<string, string> | null;
  @Getter isManager!: boolean;
  @Getter isCallAgent!: boolean;
  @Action logOut!: (payload: { redirect: string }) => void;
  @Action toggleOutlineMode!: () => void;
  @StateClass('outlineMode') outlineMode!: State['outlineMode'];

  @Watch('$i18n.locale', { immediate: true })
  onLocaleChange(): void {
    this.lang = this.$i18n.locale;
  }

  /**
   * Returns current outline mode from store.
   */
  get currentOutlineMode(): boolean {
    return this.outlineMode;
  }

  /**
   * Computed property that holds username.
   */
  get userName(): string {
    // Name set in Firestore
    if (this.getManagerProfileData) {
      return this.getManagerProfileData.name || '';
    }

    return '';
  }

  /**
   * Computed property that holds user e-mail address.
   */
  get userEmail(): string {
    if (this.getCurrentManager) {
      return this.getCurrentManager.email || '';
    }

    return '';
  }

  /**
   * Computed property whether we should show dev menu.
   */
  get showDevelopmentMenu(): boolean {
    return (
      this.getCurrentManagerRole === ManagerRole.Superadmin && (process.env.NODE_ENV! || 'unknown') !== 'production'
    );
  }

  /**
   * Show managers menu, which depends on role.
   */
  get showManagersMenuLink(): boolean {
    return this.getCurrentManagerRole === ManagerRole.Superadmin || this.getCurrentManagerRole === ManagerRole.Admin;
  }

  /**
   * Current Firebase project ID.
   */
  get firebaseProjectId(): string {
    return process.env.VUE_APP_BLOQIFY_FIREBASE_PROJECT_ID || 'unknown';
  }
}
</script>

<style scoped>
.dropdown-item a {
  color: inherit;
}
</style>
