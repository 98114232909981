import { Module } from 'vuex';
import to from 'await-to-js';
import { State } from '@/models/State';
import {
  IdentificationRequest,
  IdentificationRequestStatus,
} from '@/models/identification-requests/IdentificationRequest';
import { Counts, DataContainerStatus } from '@/models/Common';
import { bloqifyFirestore, firebase, bloqifyFunctions } from '@/boot/firebase';
import { UserTier } from '@/models/users/User';
import { Vertebra, generateState, mutateState } from '../utils/skeleton';

const SET_IDENTIFICATION_REQUEST = 'SET_IDENTIFICATION_REQUEST';

export default {
  state: generateState(),
  mutations: {
    [SET_IDENTIFICATION_REQUEST](
      state,
      { status, payload, operation }: { status: DataContainerStatus; payload?: unknown; operation: string },
    ): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async handleIdentificationRequest(
      { commit },
      { userId, action, message }: { userId: string; action: IdentificationRequestStatus; message?: string },
    ): Promise<void> {
      commit(SET_IDENTIFICATION_REQUEST, {
        status: DataContainerStatus.Processing,
        operation: 'handleIdentificationRequest',
      });

      const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

      const [handleIdentError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const countsRef = bloqifyFirestore.collection('settings').doc('counts');
          const investorRef = bloqifyFirestore.collection('investors').doc(userId);
          const iRRef = bloqifyFirestore.collection('identificationRequests').doc(userId);
          const [getIrError, getIr] = await to(transaction.get(iRRef));
          if (getIrError) {
            throw getIrError;
          }
          const status = getIr.get('status') as IdentificationRequestStatus;

          // Update IR
          transaction.update(iRRef, {
            status: action,
            ...(message && { message }),
            updatedDateTime: serverTimestamp,
          });

          if (action === IdentificationRequestStatus.Approved) {
            transaction.update(investorRef, {
              tier: UserTier.Investor,
              idRequestStatus: action,
              updatedDateTime: serverTimestamp,
            });
            transaction.set(
              countsRef,
              {
                activeInvestors: firebase.firestore.FieldValue.increment(1),
                updatedDateTime: serverTimestamp,
              } as Counts,
              { merge: true },
            );
          } else if (action === IdentificationRequestStatus.Rejected) {
            transaction.update(investorRef, {
              tier: UserTier.Account,
              idRequestStatus: action,
              updatedDateTime: serverTimestamp,
            });
          }

          // Update counts if necessary
          if (status === IdentificationRequestStatus.Initial && action !== IdentificationRequestStatus.Initial) {
            transaction.set(
              countsRef,
              {
                pendingIdentificationRequests: firebase.firestore.FieldValue.increment(-1),
                updatedDateTime: serverTimestamp,
              } as Counts,
              { merge: true },
            );
          }
        }),
      );
      if (handleIdentError) {
        commit(SET_IDENTIFICATION_REQUEST, {
          status: DataContainerStatus.Error,
          payload: handleIdentError,
          operation: 'handleIdentificationRequest',
        });
        return;
      }

      const [getUserError, getUser] = await to(bloqifyFirestore.collection('investors').doc(userId).get());
      if (getUserError || !getUser?.exists) {
        commit(SET_IDENTIFICATION_REQUEST, {
          status: DataContainerStatus.Error,
          payload: Error(`${action} action was done but could not send the email.`),
          operation: 'handleIdentificationRequest',
        });
        return;
      }

      const payload: { email: string; action: IdentificationRequestStatus } = {
        email: getUser.get('email'),
        action,
      };

      const [error] = await to(bloqifyFunctions.httpsCallable('sendIdentificationRequestEmail')(payload));
      if (error) {
        commit(SET_IDENTIFICATION_REQUEST, {
          status: DataContainerStatus.Error,
          payload: Error(`${action} action was done but could not send the email.`),
          operation: 'handleIdentificationRequest',
        });
        return;
      }

      commit(SET_IDENTIFICATION_REQUEST, {
        status: DataContainerStatus.Success,
        operation: 'handleIdentificationRequest',
      });
    },
    async deleteIdentificationRequest({ commit }, { userId }: { userId: string }): Promise<void> {
      commit(SET_IDENTIFICATION_REQUEST, {
        status: DataContainerStatus.Processing,
        operation: 'deleteIdentificationRequest',
      });

      const [transactionError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const countsRef = bloqifyFirestore.collection('settings').doc('counts');
          const [getError, getIRData] = await to(
            transaction.get(bloqifyFirestore.collection('identificationRequests').doc(userId)),
          );
          if (getError || !getIRData?.exists) {
            throw getError || Error('Identification request not found');
          }

          const identificationRequest = getIRData.data() as IdentificationRequest;

          const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

          // Removing the IR
          transaction.update(bloqifyFirestore.collection('identificationRequests').doc(userId), {
            deleted: true,
            updatedDateTime: serverTimestamp,
          });

          // Generating the object from the iR that will remove the cloned props inside investor doc
          const removalObject: { [key in keyof IdentificationRequest]: firebase.firestore.FieldValue } = Object.keys(
            identificationRequest,
          ).reduce(
            (a, key): { [key in keyof IdentificationRequest]: firebase.firestore.FieldValue } => {
              a[key] = firebase.firestore.FieldValue.delete();
              return a;
            },
            {} as { [key in keyof IdentificationRequest]: firebase.firestore.FieldValue },
          );

          // Avoid the fields that should not be removed
          // @ts-expect-error - ToDo: fix
          delete removalObject.createdDateTime;
          // @ts-expect-error - ToDo: fix
          delete removalObject.updatedDateTime;
          // @ts-expect-error - ToDo: fix
          delete removalObject.deleted;
          // @ts-expect-error - ToDo: fix
          delete removalObject.status;

          // Downgrading user and removing reference
          transaction.update(bloqifyFirestore.collection('investors').doc(userId), {
            ...removalObject,
            tier: UserTier.Account,
            identificationRequest: firebase.firestore.FieldValue.delete(),
            updatedDateTime: serverTimestamp,
          });

          // Updating counts if necessary
          if (identificationRequest.status === IdentificationRequestStatus.Initial) {
            transaction.set(
              countsRef,
              {
                pendingIdentificationRequests: firebase.firestore.FieldValue.increment(-1),
                updatedDateTime: serverTimestamp,
              } as Counts,
              { merge: true },
            );
          }
        }),
      );
      if (transactionError) {
        commit(SET_IDENTIFICATION_REQUEST, {
          status: DataContainerStatus.Error,
          payload: transactionError,
          operation: 'deleteIdentificationRequest',
        });
        return;
      }

      const [getUserError, getUser] = await to(bloqifyFirestore.collection('investors').doc(userId).get());
      if (getUserError || !getUser?.exists) {
        commit(SET_IDENTIFICATION_REQUEST, {
          status: DataContainerStatus.Error,
          payload: Error('Removal action was done but could not send the email.'),
          operation: 'deleteIdentificationRequest',
        });
        return;
      }

      const [error] = await to(
        bloqifyFunctions.httpsCallable('sendIdentificationRequestEmail')({
          email: getUser.get('email'),
          action: IdentificationRequestStatus.Rejected,
        }),
      );
      if (error) {
        commit(SET_IDENTIFICATION_REQUEST, {
          status: DataContainerStatus.Error,
          payload: Error('Removal action was done but could not send the email.'),
          operation: 'deleteIdentificationRequest',
        });
        return;
      }

      commit(SET_IDENTIFICATION_REQUEST, {
        status: DataContainerStatus.Success,
        operation: 'deleteIdentificationRequest',
      });
    },
  },
  getters: {
    getIdentificationRequestFromInvestorsListByInvestorId:
      (state, getters, rootState): ((id: string) => IdentificationRequest | undefined) =>
      (id: string): IdentificationRequest | undefined =>
        rootState.boundIdentificationRequests?.find((iR): boolean => iR.id === id),
  },
} as Module<Vertebra, State>;
