import Vue from 'vue';
import Router, { RouteConfig } from 'vue-router';
import { bloqifyAuth, firebase } from '@/boot/firebase';
import { clientConfig } from '@/helpers/clientData';
import { defaultLanguage, isValidLang, loadLanguageAsync, localStorageKey } from '@/boot/i18n';

/**
 * Custom scroll handler, to be able to use
 * URLs with a hash in it (e.g. /sandbox#alerts).
 *
 * @see https://router.vuejs.org/api/#scrollbehavior
 * @param to
 * @param from
 * @param savedPosition
 */
const scrollBehavior = (
  to,
): {
  x?: number;
  y?: number;
  selector?: string;
} => {
  // If hash is present, and not the one used by
  // Bootstrap tabs (see tabs.ts), use hash selector
  if (to.hash && !to.hash.startsWith('#/')) {
    return { selector: to.hash };
  }

  return { x: 0, y: 0 };
};

/**
 * Prefixing routes' paths to handle language
 */
const withPrefix = (prefix: string, routes: RouteConfig[]): RouteConfig[] =>
  routes.map((route): RouteConfig => {
    // Avoiding mutations
    const clonedRoute = { ...route };
    // Every route except for '/'
    if (clonedRoute.path !== '/' && clonedRoute.path !== '*') {
      clonedRoute.path = prefix + clonedRoute.path;
    }
    return clonedRoute;
  });

const whitelabelConfig = clientConfig();
const tradingBoard = !!whitelabelConfig.functionality.tradingBoardVersion;
const routes: RouteConfig[] = [
  {
    path: '*',
    redirect: '/404',
  },
  {
    path: '/',
    redirect: `${defaultLanguage}/login`,
  },
  {
    path: '/login',
    name: 'login',
    component: (): Promise<object> => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
    meta: {
      layout: 'single-fluid',
      bodyClass: 'page--login',
      title: 'Login',
    },
  },
  {
    path: '/reset',
    name: 'reset',
    component: (): Promise<object> => import(/* webpackChunkName: "reset" */ '@/views/Reset.vue'),
    meta: {
      layout: 'single-fluid',
      bodyClass: 'page--reset',
      title: 'Reset password',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: (): Promise<object> => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
    meta: {
      layout: 'sidebar-wide',
      bodyClass: 'page--dashboard',
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/investments',
    name: 'investments',
    component: (): Promise<object> => import(/* webpackChunkName: "investments" */ '@/views/Investments.vue'),
    redirect: '/investments/all',
    meta: {
      layout: 'sidebar-wide',
      bodyClass: 'page--investments',
      title: 'Investments',
      requiresAuth: true,
      allowedForManagers: true,
    },
    children: [
      {
        path: 'all',
        name: 'investments-all',
        component: (): Promise<object> =>
          import(/* webpackChunkName: "investments" */ '@/components/investments/All.vue'),
        meta: {
          layout: 'sidebar--wide',
          bodyClass: 'page--investments-all',
          title: 'Investments all',
        },
      },
      {
        path: 'loans',
        name: 'investments-loans',
        component: (): Promise<object> =>
          import(/* webpackChunkName: "investments" */ '@/components/investments/Loans.vue'),
        meta: {
          layout: 'sidebar--wide',
          bodyClass: 'page--investments-loans',
          title: 'Investments loans',
        },
      },
    ],
  },
  {
    path: '/investments/create-modify-payment/:investmentId?/:paymentId?',
    name: 'create-modify-payment',
    component: (): Promise<object> => import(/* webpackChunkName: "users" */ '@/views/CreatePayment.vue'),
    meta: {
      layout: 'sidebar--wide',
      bodyClass: 'page--create-payment',
      title: 'Create & Modify Payment',
      requiresAuth: true,
    },
  },
  ...(tradingBoard
    ? [
        {
          path: '/trading-board',
          name: 'trading-board',
          component: (): Promise<object> => import(/* webpackChunkName: "users" */ '@/views/TradingBoard.vue'),
          redirect: '/trading-board/open',
          meta: {
            layout: 'sidebar-wide',
            bodyClass: 'page--trading-board',
            title: 'Trading Board',
            requiresAuth: true,
          },
          children: [
            {
              path: 'open',
              name: 'trading-board-open',
              component: (): Promise<object> =>
                import(/* webpackChunkName: "users" */ '@/components/tradingboard/Open.vue'),
              meta: {
                layout: 'sidebar--wide',
                bodyClass: 'page--trading-board-open',
                title: 'Trading Board open',
              },
            },
            {
              path: 'openSell/:assetId?',
              name: 'trading-board-open-sell-offers',
              component: (): Promise<object> =>
                import(/* webpackChunkName: "users" */ '@/components/tradingboard/OpenSellOffers.vue'),
              meta: {
                layout: 'sidebar--wide',
                bodyClass: 'page--trading-board-open-offers',
                title: 'Open Offers',
              },
            },
            {
              path: 'openBuy/:assetId?',
              name: 'trading-board-open-buy-offers',
              component: (): Promise<object> =>
                import(/* webpackChunkName: "users" */ '@/components/tradingboard/OpenBuyOffers.vue'),
              meta: {
                layout: 'sidebar--wide',
                bodyClass: 'page--trading-board-open-offers',
                title: 'Open Offers',
              },
            },
            {
              path: 'closed',
              name: 'trading-board-closed',
              component: (): Promise<object> =>
                import(/* webpackChunkName: "users" */ '@/components/tradingboard/Closed.vue'),
              meta: {
                layout: 'sidebar--wide',
                bodyClass: 'page--trading-board-closed',
                title: 'Trading Board closed',
              },
            },
          ],
        },
      ]
    : []),
  {
    path: '/investments/transfer',
    name: 'investments-transfer',
    component: (): Promise<object> => import(/* webpackChunkName: "investments" */ '@/views/Transfer.vue'),
    meta: {
      layout: 'sidebar--wide',
      bodyClass: 'page--investments-transfer',
      title: 'Investments Transfer',
      requiresAuth: true,
    },
  },
  {
    path: '/users',
    name: 'users',
    component: (): Promise<object> => import(/* webpackChunkName: "users" */ '@/views/Users.vue'),
    redirect: '/users/all',
    meta: {
      layout: 'sidebar-wide',
      bodyClass: 'page--users',
      title: 'Users',
      requiresAuth: true,
      allowedForManagers: true,
    },
    children: [
      {
        path: 'all',
        name: 'users-all',
        component: (): Promise<object> =>
          import(/* webpackChunkName: "users" */ '@/components/users/TabAllInvestors.vue'),
        meta: {
          layout: 'sidebar--wide',
          bodyClass: 'page--users-all',
          title: 'Users all',
        },
      },
    ],
  },
  {
    path: '/user-details/:userId/',
    name: 'user-details',
    component: (): Promise<object> => import(/* webpackChunkName: "users" */ '@/views/UserDetails.vue'),
    props: { approvalMode: false },
    meta: {
      layout: 'sidebar--wide',
      bodyClass: 'page--user-details',
      title: 'User Details',
      requiresAuth: true,
      allowedForManagers: true,
    },
  },
  {
    path: '/user-details/:userId/edit',
    name: 'user-details-edit',
    component: (): Promise<object> => import(/* webpackChunkName: "users" */ '@/views/CreateUser.vue'),
    props: { approvalMode: false },
    meta: {
      layout: 'sidebar--wide',
      bodyClass: 'page--user-details',
      title: 'Edit User Details',
      requiresAuth: true,
    },
  },
  {
    path: '/user-details/:userId/approval',
    name: 'user-details-approval',
    component: (): Promise<object> => import(/* webpackChunkName: "users" */ '@/views/UserDetails.vue'),
    props: { approvalMode: true },
    meta: {
      layout: 'sidebar--wide',
      bodyClass: 'page--user-details',
      title: 'Approve User',
      requiresAuth: true,
    },
  },
  {
    path: '/user-create',
    name: 'user-create',
    component: (): Promise<object> => import(/* webpackChunkName: "users" */ '@/views/CreateUser.vue'),
    props: { approvalMode: true },
    meta: {
      layout: 'sidebar--wide',
      bodyClass: 'page--user-details',
      title: 'Approve User',
      requiresAuth: true,
    },
  },
  {
    path: '/assets',
    name: 'assets',
    component: (): Promise<object> => import(/* webpackChunkName: "assets" */ '@/views/Assets.vue'),
    meta: {
      layout: 'sidebar-wide',
      bodyClass: 'page--assets',
      title: 'Assets',
      requiresAuth: true,
    },
  },
  {
    path: '/create-modify-asset/:assetId?',
    name: 'create-modify-asset',
    component: (): Promise<object> => import(/* webpackChunkName: "create-asset" */ '@/views/CreateAsset.vue'),
    meta: {
      layout: 'sidebar--wide',
      bodyClass: 'page--create-asset',
      title: 'Create & Modify Asset',
      requiresAuth: true,
    },
  },
  {
    path: '/managers',
    name: 'Managers',
    component: (): Promise<object> => import(/* webpackChunkName: "managers" */ '@/views/Managers.vue'),
    meta: {
      layout: 'sidebar-wide',
      bodyClass: 'page--managers',
      title: 'Managers',
      requiresAuth: true,
    },
  },
  {
    path: '/tracing',
    name: 'Tracing',
    component: (): Promise<object> => import(/* webpackChunkName: "tracing" */ '@/views/Tracing.vue'),
    meta: {
      layout: 'sidebar-wide',
      bodyClass: 'page--tracing',
      title: 'Tracing',
      requiresAuth: true,
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: (): Promise<object> => import(/* webpackChunkName: "settings" */ '@/views/Settings.vue'),
    redirect: '/settings/general',
    meta: {
      layout: 'sidebar',
      bodyClass: 'page--settings',
      title: 'Settings',
      requiresAuth: true,
    },
    children: [
      {
        path: 'test-email',
        name: 'settings-testEmail',
        component: (): Promise<object> =>
          import(/* webpackChunkName: "settings" */ '@/components/account/settings/TestEmail.vue'),
        meta: {
          layout: 'sidebar--wide',
          bodyClass: 'page--settings-general',
          title: 'Test Emails',
        },
      },
      {
        path: 'general',
        name: 'settings-general',
        component: (): Promise<object> =>
          import(/* webpackChunkName: "settings" */ '@/components/account/settings/General.vue'),
        meta: {
          layout: 'sidebar--wide',
          bodyClass: 'page--settings-general',
          title: 'Settings general',
        },
      },
      {
        path: 'security',
        name: 'settings-security',
        component: (): Promise<object> =>
          import(/* webpackChunkName: "settings" */ '@/components/account/settings/Security.vue'),
        meta: {
          layout: 'sidebar--wide',
          bodyClass: 'page--settings-security',
          title: 'Settings security',
        },
      },
      {
        path: 'messaging',
        name: 'settings-messaging',
        component: (): Promise<object> =>
          import(/* webpackChunkName: "settings" */ '@/components/account/settings/Messaging.vue'),
        meta: {
          layout: 'sidebar--wide',
          bodyClass: 'page--settings-messaging',
          title: 'Settings messaging',
        },
      },
      {
        path: 'questionnaire',
        name: 'settings-questionnaire',
        component: (): Promise<object> =>
          import(/* webpackChunkName: "settings" */ '@/components/account/settings/Questionnaire.vue'),
        meta: {
          layout: 'sidebar--wide',
          bodyClass: 'page--settings-questionnaire',
          title: 'Settings questionnaire',
        },
      },
      {
        path: 'client-data',
        name: 'settings-client-data',
        component: (): Promise<object> =>
          import(/* webpackChunkName: "settings" */ '@/components/account/settings/ClientData.vue'),
        meta: {
          layout: 'sidebar--wide',
          bodyClass: 'page--settings-general',
          title: 'Client data',
        },
      },
      {
        path: 'restore-backup',
        name: 'settings-restore-backup',
        component: (): Promise<object> =>
          import(/* webpackChunkName: "settings" */ '@/components/account/settings/RestoreBackup.vue'),
        meta: {
          layout: 'sidebar--wide',
          bodyClass: 'page--settings-restore-backup',
          title: 'Settings restore backup',
        },
      },
      {
        path: 'investor-portal',
        name: 'settings-investments-config',
        component: (): Promise<object> =>
          import(/* webpackChunkName: "settings" */ '@/components/account/settings/InvestorPortal.vue'),
        meta: {
          layout: 'sidebar--wide',
          bodyClass: 'page--settings-general',
          title: 'Investor portal',
        },
      },
    ],
  },
  {
    path: '/404',
    name: '404',
    component: (): Promise<object> => import(/* webpackChunkName: "404" */ '@/views/NotFound.vue'),
    meta: {
      layout: 'single',
      bodyClass: 'page--404',
      title: 'Page not found',
    },
  },
];

routes.push({
  path: '/create-modify-project/:assetId?/:projectId?',
  name: 'create-modify-project',
  component: (): Promise<object> => import(/* webpackChunkName: "create-loan" */ '@/views/CreateProject.vue'),
  meta: {
    layout: 'sidebar--wide',
    bodyClass: 'page--create-loan',
    title: 'Create & Modify Loan',
    requiresAuth: true,
  },
});

routes.push({
  path: '/asset-financials/:assetId?',
  name: 'asset-financials',
  component: (): Promise<object> => import(/* webpackChunkName: "create-asset" */ '@/views/AssetFinancials.vue'),
  meta: {
    layout: 'sidebar--wide',
    bodyClass: 'page--asset-financials',
    title: 'Asset Financials',
    requiresAuth: true,
  },
});

/**
 * Initialize Vue router.
 *
 * @see https://router.vuejs.org
 */
const VueRouter = new Router({
  mode: 'history',
  // @ts-expect-error - Wrong Types?
  scrollBehavior,
  base: process.env.BASE_URL,
  routes: withPrefix('/:lang?', routes),
});

VueRouter.beforeEach(async (to, from, next): Promise<void> => {
  const fromPathWithoutLang = from.path.substring(3);
  const toPath = to.path;
  // Prevents navigation guard error for unneded rerouting from lang/url to url.
  if (
    fromPathWithoutLang === toPath ||
    (!to.params.lang && to.path !== '/' && to.path !== '/404' && to.path !== '/reset')
  ) {
    return;
  }

  const { currentUser } = bloqifyAuth;
  const requiresAuth = to.matched.some((record): boolean => record.meta.requiresAuth);
  const allowedForManagers = to.matched.some((record): boolean => record.meta.allowedForManagers);

  // Setting language according to where we go:
  // If user wants to change to specific lang (to)
  // If user remains in same lang (from)
  // User is in no lang mode (defaultLanguage)
  const { lang } = to.params;
  const { lang: previousLang } = from.params;
  const localStorageLang = localStorage.getItem(localStorageKey);
  const toLang = lang || previousLang || localStorageLang || defaultLanguage;
  const fullToLang = toLang ? `/${toLang}` : '';
  if (isValidLang(toLang)) {
    void loadLanguageAsync(toLang);
  } else {
    next(to.path.replace(toLang, defaultLanguage));
    return;
  }

  // Checks if the user is enrolled in PHONE multifactor
  const enrolledMultifactor = currentUser?.multiFactor.enrolledFactors.some(
    (multiFactor): boolean => multiFactor.factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID,
  );

  const tokenResult = await currentUser?.getIdTokenResult();

  const tokenClaims = tokenResult?.claims;
  const isAdmin =
    tokenClaims &&
    (!!tokenClaims.superadmin ||
      !!tokenClaims.admin ||
      !!tokenClaims.editor ||
      !!tokenClaims.manager ||
      !!tokenClaims['call-agent']);

  // prevent Call Agent from accessing forbidden routes
  if (
    tokenClaims?.['call-agent'] &&
    ((fromPathWithoutLang.includes('login') && toPath.includes('/dashboard')) ||
      toPath.includes('/dashboard') ||
      toPath.includes('investments') ||
      toPath.includes('assets') ||
      toPath.includes('managers') ||
      toPath.includes('settings'))
  ) {
    next({ path: `/${toLang}/users/all` });
  }

  // Set page title and body class using Vue Meta
  // @ts-expect-error - ToDo: Fix Types
  if (document.body && to.meta.bodyClass) {
    // @ts-expect-error - ToDo: Fix Types
    document.body.className = to.meta.bodyClass;
  }
  if (tokenClaims?.manager && !allowedForManagers) {
    next(from.meta?.allowedForManagers ? from.meta.path : '/investments');
  }

  if (requiresAuth && (!currentUser || !isAdmin || !enrolledMultifactor)) {
    // Need to login
    next({ path: `${defaultLanguage}/login` });
  } else if (!requiresAuth && currentUser && enrolledMultifactor && from.path === '/login' && to.path !== '/404') {
    // After login go to dashboard
    if (tokenClaims && !tokenClaims['call-agent']) {
      next({ path: '/dashboard' });
    } else {
      next({ path: '/users/all' });
    }
  } else if (currentUser && enrolledMultifactor && to.path === '/login') {
    // Go to dashboard when requesting login page while being logged in
    if (tokenClaims && !tokenClaims['call-agent']) {
      next({ path: '/dashboard' });
    } else {
      next({ path: '/users/all' });
    }
  } else {
    const redirectTo = lang ? to.fullPath : `${fullToLang}${to.fullPath}`;
    if (to.fullPath !== redirectTo) {
      next({ path: redirectTo, query: to.query });
      return;
    }
    next();
  }
});

// Use Vue router instance and init Vue body class component
// and Vue page titles component to set page classes and titles
Vue.use(Router);

export default VueRouter;
