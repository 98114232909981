import firebase from 'firebase/compat/app';

export const enum DataContainerStatus {
  Initial = 'initial',
  Processing = 'processing',
  Success = 'success',
  Error = 'error',
}
export interface DataContainerInterface<T = unknown> {
  status: DataContainerStatus;
  payload: T | null;
  error?: string | Error | firebase.auth.Error;
  name?: string;
  length?: number | null;

  loading(): boolean;
}

export class DataContainer<T> implements DataContainerInterface<T> {
  status: DataContainerStatus = DataContainerStatus.Initial;
  payload = null;
  error = '';
  name = '';
  length = null;

  /**
   * Returns whether data is loading.
   */
  public loading(): boolean {
    return this.status === DataContainerStatus.Initial || this.status === DataContainerStatus.Processing;
  }
}

export interface VuexfireSnapshot {
  id: string;
}

export interface FundCard {
  showFundSize: boolean;
  showAvailable: boolean;
  showDividendFormat: boolean;
}

export enum DividendFormatOptions {
  divComponent = 'Div Format',
  calculation = 'calculation',
}
export interface AvailableCard extends FundCard {
  divFormatFormat?: DividendFormatOptions;
}

export interface MyTransactions {
  showMyTransactions: boolean;
  showDividendsFormat: boolean;
  showPaymentStatus: boolean;
  showOnlineOffline: boolean;
  showWithdrawalButton: boolean;
}

export interface MarketplaceCard {
  showDividendsFormat: boolean;
  showLocation: boolean;
  showDate: boolean;
  showFundSize: boolean;
  showAvailability: boolean;
  showDocuments: boolean;
}

export interface CheckoutFlow {
  switchToUnits: boolean;
  showExpectedEarnings: boolean;
  investmentConditions: {
    en: string;
    nl: string;
  };
}

export interface InvestedCard extends FundCard {
  showInitialInvestment: boolean;
  initialInvestmentFrom: 'initial' | 'current';
  showCurrentInvestment: boolean;
  showEarnings: boolean;
  showFundsEarnings: boolean;
  showReturnPercentage: boolean;
  showReturnValue: boolean;
  showTotalReturn: boolean;
  showCost: boolean;
  showFundsCosts: boolean;
  showRepayments: boolean;
  divInvestedFormatFormat?: DividendFormatOptions;
  showDividendFormatFrom?: 'calculation' | 'percentage';
}

export interface HistoryCard {
  showEarnings: boolean;
  showRepayments: boolean;
  showCosts: boolean;
  showLegalDocs: boolean;
  showPaymentStatus: boolean;
  showUnitsRepaid: boolean;
}

export enum AssetVisibility {
  Invested = 'invested',
  NotFull = 'not-full',
}

export interface PropertiesPage {
  hideMarketplace: boolean;
  assetVisibility: AssetVisibility[];
}

export enum ReturnOptions {
  percentage = 'percentage',
  amount = 'amount',
  both = 'both',
}

export enum EarningsOptions {
  actual = 'actual',
  expected = 'expected',
}

export interface Global {
  decimalsForNumbers: 2 | 3;
  removeDecimalsIfWholeNumbers: boolean;
  decimalsForPercentages: 2 | 3;
  removeDecimalsIfWholePercentages: boolean;
}

export interface Dashboard {
  initialAmountFrom: 'initial' | 'current';
  showMyReturn: boolean;
  showDirectReturn: boolean;
  showIndirectReturn: boolean;
  indirectReturnFormat: ReturnOptions;
  showTotalReturn: boolean;
  totalReturnFormat: ReturnOptions;
  showMyEarnings: boolean;
  earningsFormat: EarningsOptions;
  showTaxStatement: boolean;
}

export interface RegistrationSettings {
  showRegistrationButton: boolean;
}

export interface Navbar {
  addPortfolioToNavbar: boolean;
  showHomeButton: boolean;
  logoUrl?: string;
}

export interface UserVerification {
  private: boolean;
  business: boolean;
}

export interface ContactSettings {
  showWhatsappButton: boolean;
}

export interface BulletinBoard {
  createBuyConditions: {
    en: string;
    nl: string;
  };
  createSellConditions: {
    en: string;
    nl: string;
  };
  acceptBuyOfferConditions: {
    en: string;
    nl: string;
  };
  acceptSellOfferConditions: {
    en: string;
    nl: string;
  };
  negotiateOfferConditions: {
    en: string;
    nl: string;
  };
}

export interface InvestorPortalConfig {
  global: Global;
  navbar: Navbar;
  userVerification: UserVerification;
  dashboard: Dashboard;
  availableCard: AvailableCard;
  myTransactions: MyTransactions;
  marketplaceCard: MarketplaceCard;
  checkoutFlow: CheckoutFlow;
  investedCard: InvestedCard;
  historyDetailsOptions: HistoryCard;
  propertiesPage: PropertiesPage;
  registration: RegistrationSettings;
  contact: ContactSettings;
  bulletinBoard: BulletinBoard;
}

export interface AdminPortalConfig {
  createUser: {
    sendConfirmationEmail: boolean;
  };
  blockAccessToPlatform: boolean;
}

export interface FileHandler {
  name: string;
  fullPath: string;
  file: File;
}

// Client Docs
export interface ClientDocs {
  logo: string[] | FileHandler[];
}
export type DocsFields = keyof ClientDocs;

// Client Data
export interface ClientData {
  companyName: string;
  email: string;
  url: string;
  dir: string;
  telephone: string;
  whatsapp: string;
  kvk?: string;
  iban?: string;
  bic?: string;
  btw?: string;
}

export enum AlignmentOptions {
  left = 'left',
  center = 'center',
  right = 'right',
}

export enum SizeOptions {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum StyleOptions {
  Regular = 'Regular',
  Italic = 'Italic',
  Bold = 'Bold',
  BoldItalic = 'BoldItalic',
}

export enum FontOptions {
  Brother1816 = 'Brother1816',
  DMsans = 'DMsans',
  Arial = 'Arial',
}

// Doc Format
export interface DocFormat {
  logoAlignment: AlignmentOptions;
  logoSize: SizeOptions;
  titleAlignment: AlignmentOptions;
  titleSize: SizeOptions;
  titleStyle: StyleOptions;
  fontSize: SizeOptions;
  font: FontOptions;
  showEarningTaxes: boolean;
}

export interface Settings {
  assetTypes: object;
  maintenance: boolean;
  bloqadminMaintenance: boolean;
  restoring: boolean;
  investorPortalConfig: InvestorPortalConfig;
  clientData: ClientData;
  clientDocs: ClientDocs;
  docFormat: DocFormat;
  adminPortalConfig: AdminPortalConfig;
  bloqifyVersion: string;
  bloqadminVersion: string;
  bloqifyDevVersion: string;
  bloqadminDevVersion: string;
}

export interface IdentificationSettings {
  requireApproval: {
    business: boolean;
    world: boolean;
  };
}

export interface Counts {
  activeInvestors: number | firebase.firestore.FieldValue;
  activeUsers: number | firebase.firestore.FieldValue;
  paidPayments: number | firebase.firestore.FieldValue;
  openPayments: number | firebase.firestore.FieldValue;
  pendingIdentificationRequests: number | firebase.firestore.FieldValue;
  publishedAssets: number | firebase.firestore.FieldValue;
  updatedDateTime: firebase.firestore.Timestamp;
}

export interface Backup {
  location: {
    bucket: string;
    path: string;
  };
  service: object;
}
