type RequiredFieldOptions =
  | 'customId'
  | 'projectName'
  | 'fundType'
  | 'signChoice'
  | 'kycMethod'
  | 'companyId'
  | 'gender'
  | 'initials'
  | 'investorName'
  | 'middleName'
  | 'name'
  | 'surname'
  | 'telephone'
  | 'email'
  | 'dateOfBirth'
  | 'streetAddress'
  | 'houseNumber'
  | 'postalCode'
  | 'city'
  | 'country'
  | 'status'
  | 'startDateTime'
  | 'createdDateTime'
  | 'kvk'
  | 'passportExpirationDate'
  | 'bankAccount'
  | 'bankAccountOwner'
  | 'subscribeNewsLetter'
  | 'receivePhysicalMailings'
  | 'emissionCost'
  | 'paidEuroTotal'
  | 'boughtSharesTotal'
  | 'totalEuroEarnings'
  | 'totalEuroRepayments'
  | 'dateOfBirthPartner'
  | 'experience'
  | 'tier'
  | 'kvkImage'
  | 'nationality'
  | 'passport'
  | 'passportSecond'
  | 'riskClass'
  | 'updatedDateTime'
  | 'paidPayments'
  | 'openEuroTotal'
  | 'IBAN'
  | 'custom';

type CollectionOptions = 'investor' | 'investment' | 'asset';

export enum CollectionOptionsEnum {
  investor = 'investor',
  investment = 'investment',
  asset = 'asset',
}

interface FieldObject {
  fieldName: string;
  origin: CollectionOptions;
  field: RequiredFieldOptions;
}

type FieldObjectMap = Record<string, FieldObject>;

export enum PaymentProviderType {
  Mollie = 'Mollie',
  PayNL = 'PayNL',
  OPP = 'OPP',
  /**
   * Manual, from Bloqadmin.
   */
  Custom = 'Custom',
}

export interface GreenTea {
  scssVariables: string;
  maxIddleTime: number;
  internalClientName: string;
  name: string;
  pescheckEnabled: boolean;
  paymentServiceProvider: PaymentProviderType;
  opp: {
    source: 'asset' | 'manual'; // Switch asset wallet behavior, asset created wallets or manually input
  };
  functionality: {
    tradingBoardVersion?: 'one' | 'two';
    bulkImport: boolean;
    includeOpenPaymentsInAssetExport: boolean;
    checkoutSessionEnabled: boolean;
    taxStatementYears: number;
    languageChoice: {
      enableLanguageChoice: boolean;
      languages: string[];
      defaultLanguage: string;
    };
    modules: {
      asset: {
        requiredFields: string[];
        fileKeyNames: string[];
      };
    };
    createAsset: {
      showLocationToggle: boolean;
      subscriptionDocEnabled: boolean;
      secondaryDocEnabled: boolean | number;
      assetTermsOrLoanAgreementEnabled: boolean;
      additionalFilesForInvestmentsEnabled: boolean;
    };
    userDetails: {
      preferredLanguage: boolean;
    };
    createUser: {
      initialsRequired: boolean;
      identifierEnabled: boolean;
      riskClassificationRequired: boolean;
      kvkRequired: boolean;
      bankAccountOwnerRequired: boolean;
    };
    users: {
      pdfDownloadFunctionName: string;
    };
    investments: {
      earningsEnabled: boolean;
      repaymentsEnabled: boolean;
      loansTabEnabled: boolean;
      fractionalSharesEnabled: boolean;
    };
    payments: {
      cancellableEnabled: boolean;
      endingDisabled: boolean;
      endingDaySelector: boolean;
      markAsRequestedEnabled: boolean;
    };
    downloadFundInvestors: {
      // Some fields are the same for multiple collections, so we will pass the origin to distinguish.
      // Also each client will want their own fieldNames, so we will pass that too.
      requiredFields: FieldObjectMap;
    };
    investorPortal: {
      availableFundCard: {
        divFormatTypeEnabled: boolean;
      };
    };
  };
}
