<template>
  <div id="app">
    <div v-if="!maintenanceMode || isUserSuperAdmin">
      <toast position="ne" />
      <vue-headful :title="this.$route.meta?.title" lang="en" />
      <component :is="layout" v-cloak>
        <div
          v-if="maintenanceMode && isUserSuperAdmin"
          class="alert alert-center text-center alert-danger rounded-0 maintenance-alert"
        >
          The website is under maintenance but since you are superadmin you can still see the website.
        </div>
        <router-view />
      </component>
    </div>
    <div v-else class="container text-center pt-5">
      <div class="mt-5 mx-auto">
        <div class="alert alert-danger d-inline-block" role="alert">
          <div v-if="maintenanceMode">
            <font-awesome-icon class="mr-4" :icon="['fa', 'exclamation-triangle']" />
            <strong>Website under maintenance.</strong>
          </div>
        </div>
      </div>
      <div>
        <p class="text-muted mx-auto">Please contact for support.</p>
      </div>
    </div>
    <RefreshModal v-if="shouldShowRefreshModal" @click-on-refresh="refreshPage" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, State as StateClass } from 'vuex-class';
import changeCase from 'change-case';
import { Toast } from 'vuex-toast';
import { State } from '@/models/State';
import Sidebar from '@/components/navigation/Sidebar.vue';
import { ManagerRole } from '@/models/manager/Manager';
import initialiseValidators from '@/boot/validators';
import { loadLanguageAsync } from '@/boot/i18n';
import RefreshModal from '@/components/common/refresh-modal/RefreshModal.vue';
import { bloqify } from '@/boot/firebase';

const isProductionEnvironment = process.env.NODE_ENV === 'production';
// @ts-expect-error - ToDo: Fix this
const isDevProject = bloqify.options.projectId.includes('development');

initialiseValidators();

@Component({
  components: {
    Toast,
    Sidebar,
    RefreshModal,
  },
})
export default class App extends Vue {
  defaultLayout = 'single';
  bundleVersion = '';

  @Action refreshAuthenticatedUserToken!: () => void;
  @Action logOut!: (payload: { redirect: string; idle: boolean }) => void;

  @StateClass manager!: State['manager'];
  @StateClass currentManager!: State['currentManager'];
  @StateClass settings!: State['settings'];
  @Getter getCurrentManagerRole!: ManagerRole;

  async mounted(): Promise<void> {
    if (process.env.NODE_ENV !== 'development') {
      this.bundleVersion = await this.fetchVersion();
    }
  }

  // The idea here is to merge the translations of the asset types from runtime configs directly into lang file in use
  @Watch('settings', { deep: true })
  async onFireStoreSettingsChange(newState: State['settings']): Promise<void> {
    if (newState && newState.assetTypes) {
      const lang = this.$i18n.locale;
      if (newState.assetTypes[lang]) {
        // @ts-expect-error - ToDo: Fix this
        await loadLanguageAsync(lang, { assetTypes: newState.assetTypes[lang] });
      }
    }
  }

  @Watch('bundleVersion')
  @Watch('settings')
  onBundleVersionChange(): void {
    const versionInFirestore = isDevProject ? this.settings?.bloqadminDevVersion : this.settings?.bloqadminVersion;
    if (versionInFirestore) {
      console.group('Bloqify ~');
      console.log(`Bundle version: ${isProductionEnvironment ? this.bundleVersion : 'development'}.`);
      console.log(`Version in Firestore: ${versionInFirestore}.`);
      console.groupEnd();
    }
  }

  @Watch('manager', { deep: true })
  onFireStoreUserChange(newState: State['manager'], oldState: State['manager']): void {
    if (newState !== null && oldState !== null) {
      // Role changed, so make sure client side we're up to date
      if (newState.metadata.roleSetTime !== oldState.metadata.roleSetTime) {
        this.refreshAuthenticatedUserToken();
      }
    }
  }

  /**
   * Log out user when idle.
   */
  @Watch('isAppIdle')
  onNewAppIdle(newAppIdle: boolean, oldAppIddle: boolean): void {
    if (newAppIdle && !oldAppIddle && this.currentManager) {
      this.logOut({ redirect: `/${this.$i18n.locale}/login`, idle: true });
    }
  }

  /**
   * Showing a modal to the user if the version in Firestore is different from the bundle version.
   */
  get shouldShowRefreshModal(): boolean {
    const versionInFirestore = isDevProject ? this.settings?.bloqifyDevVersion : this.settings?.bloqifyVersion;

    if (!isProductionEnvironment || !versionInFirestore || !this.bundleVersion) {
      return false;
    }

    return versionInFirestore !== this.bundleVersion;
  }

  /**
   * Computed property to return the layout defined
   * in router.ts as meta property. Returns CamelCased
   * layout (i.e. Vue component defined in layouts.ts).
   */
  get layout(): string {
    const layout: string = this.$route.meta?.layout || this.defaultLayout;

    return changeCase.pascalCase(`${layout}-layout`);
  }

  get isUserSuperAdmin(): boolean {
    if (this.getCurrentManagerRole) {
      return this.getCurrentManagerRole === ManagerRole.Superadmin;
    }
    return false;
  }

  get maintenanceMode(): boolean {
    if (!this.settings) {
      return false;
    }
    return this.settings.bloqadminMaintenance;
  }

  refreshPage(): void {
    window.location.reload();
  }

  /**
   * Asumes CircleCI has created a file in /public with the version string.
   * This is used to compare the bundle version from the file with the version in Firestore.
   */
  async fetchVersion(): Promise<string> {
    try {
      const response = await fetch('/version');
      if (!response.ok) {
        throw new Error('Error fetching version from file; network error.');
      }
      const version = await response.text();
      return version.trim();
    } catch (error) {
      console.error('Error fetching version from file:', error);
      throw error;
    }
  }
}
</script>

<style lang="scss">
[v-cloak] {
  display: none;
}

.maintenance-alert {
  margin-bottom: 0;
}
</style>
