import { Person, PersonInterface } from './Person';

export interface SpecialUser {
  readonly id: string;
  email: string;
  superadmin: boolean;
  admin: boolean;
  editor: boolean;
  manager: boolean;
  identifier?: string;
}

export const enum ManagerStatus {
  Disabled = 'disabled',
  Enabled = 'enabled',
}

export enum ManagerRole {
  Superadmin = 'superadmin',
  Admin = 'admin',
  Editor = 'editor',
  Manager = 'manager',
  CallAgent = 'call-agent',
}

export interface ManagerInterface extends PersonInterface {
  status: ManagerStatus;
  role: ManagerRole;
}

export interface ManagerProfile {
  name: string;
  team: string;
  biography: string;
  skype: string;
  slack: string;
  telephone: string;
}

export interface ManagerMetaData {
  roleSetTime: number;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
export interface Manager {
  readonly id: string;
  metadata: ManagerMetaData;
  profile: ManagerProfile;
  identifier?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
export class Manager extends Person implements ManagerInterface {
  role = ManagerRole.Editor;
  status = ManagerStatus.Enabled;

  /**
   * Constructor.
   */
  public constructor(
    uid: string,
    status: ManagerStatus,
    role: ManagerRole,
    email: string,
    createdAt?: string,
    lastSignInAt?: string,
  ) {
    super(uid, email, createdAt, lastSignInAt);
    this.role = role;
    this.status = status;
  }
}
