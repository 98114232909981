import firebase from 'firebase/compat/app';
import { Asset } from '../assets/Asset';
import { Investor } from '../users/User';

/**
 * Defining all the statuses a Payment can have.
 */
export const enum PaymentStatus {
  Open = 'open',
  Canceled = 'canceled',
  Pending = 'pending',
  Authorized = 'authorized',
  Expired = 'expired',
  Failed = 'failed',
  Paid = 'paid',
  Requested = 'requested',
}

/**
 * Different payment gateways/providers we use.
 */
export enum PaymentProvider {
  Mollie = 'Mollie',
  PayNL = 'PayNL',
  OPP = 'OPP',
  /**
   * Manual, from Bloqadmin.
   */
  Custom = 'Custom',
}

/**
 * The upper db object for an Investment.
 * An investments always contains a subcollection of Payments and maybe also Earnings.
 */
export interface Investment {
  deleted: boolean;
  id?: string;
  investor: firebase.firestore.DocumentReference | Investor;
  asset: firebase.firestore.DocumentReference | Asset;
  paidEuroTotal?: number;
  boughtSharesTotal?: number;
  totalEuroRepayments?: number;
  totalSharesRepayments?: number;
  totalEuroEarnings?: number;
  totalEuroCosts?: number;
  openPayments?: number | firebase.firestore.FieldValue;
  paidPayments?: number | firebase.firestore.FieldValue;
  deletedPayments?: number | firebase.firestore.FieldValue;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime?: firebase.firestore.Timestamp;
  signChoice?: string;
  identifier?: string;
}

/**
 * Sell or buy operation
 */
export const enum PaymentType {
  Sell = 'sell',
  Buy = 'buy',
}

/**
 * Actual payment info is placed here.
 */
export interface DefaultPayment {
  id?: string;
  investor: firebase.firestore.DocumentReference | Investor;
  investment: firebase.firestore.DocumentReference | Investment;
  asset: firebase.firestore.DocumentReference | Asset;
  bid?: firebase.firestore.DocumentReference; // If the payment has been created as a result of a bid exchange
  dividendsFormat: [string, number];
  provider: PaymentProvider;
  providerData: {
    id: string;
    amount: {
      currency: string;
      value: string | number;
    };
    status: PaymentStatus;
    metadata: {
      uid?: string;
      euroAmount: number;
      sharesAmount: number;
      investmentId?: string;
      assetId?: string;
      paymentId?: string;
      selectedDividendsFormatYear?: [string, number];
    };
    [key: string]: unknown;
  };
  legalDocuments?: string[];
  type?: string;
  deleted: boolean;
  ended?: firebase.firestore.Timestamp;
  scheduleEnding?: boolean;
  sharesReserved?: number;
  usedEndPayments?: {
    investmentId: string;
    paymentId: string;
    amount: number;
  }[];
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime?: firebase.firestore.Timestamp;
  paymentDateTime?: firebase.firestore.Timestamp;
  endDateTime?: firebase.firestore.Timestamp;
  transactionDate?: firebase.firestore.Timestamp;
  comment?: string;
  from?: firebase.firestore.DocumentReference | DefaultPayment;
}

export interface InvestmentsOverview {
  investorId?: string;
  investorName?: string;
  assetId?: string;
  fundName?: string;
  totalInvested?: number;
  totalCurrent?: number;
  totalEarning?: number;
  totalCosts?: number;
}

export type HighlightCondition = {
  field: string;
  condition: 'less' | 'more' | 'equal';
  value: unknown;
} | null;

export interface PaymentRelation {
  ref: firebase.firestore.DocumentReference;
  sharesAmount: number;
}

export interface BuyingPayment extends DefaultPayment {
  type: PaymentType.Buy;
  from?: firebase.firestore.DocumentReference;
  usedShares: PaymentRelation[] | firebase.firestore.FieldValue;
}

export interface SellingPayment extends DefaultPayment {
  type: PaymentType.Sell;
  to?: firebase.firestore.DocumentReference;
  usedPayments: PaymentRelation[] | firebase.firestore.FieldValue;
}

export type Payment = BuyingPayment | SellingPayment | DefaultPayment;
/**
 * Defining all the statuses a Bid can have.
 */
export enum BidStatus {
  Open = 'open',
  Reserved = 'reserved',
  Completed = 'completed',
  Expired = 'expired',
  Cancelled = 'cancelled',
  Failed = 'failed',
}

/**
 * Different bid gateways/providers we use.
 */
export const enum BidProvider {
  Opp = 'opp',
  Custom = 'custom',
}

/**
 * Sell or buy operation
 */
export const enum BidType {
  Sell = 'sell',
  Buy = 'buy',
}

export interface BidIds {
  investmentId: string;
  bidId: string;
}

export interface DefaultBid {
  id?: string;
  ref?: firebase.firestore.DocumentReference;
  investor: firebase.firestore.DocumentReference | Investor | string;
  investment: firebase.firestore.DocumentReference | Investment;
  asset: firebase.firestore.DocumentReference | Asset | string;
  status: BidStatus;
  type: BidType;
  provider: BidProvider;
  partner?: firebase.firestore.DocumentReference | DefaultBid; // Related bid (sell - buy relationship). Setted once the bid is reserved
  payment?: firebase.firestore.DocumentReference; // Payment created when the exchange is complete
  deleted: boolean;
  mode: 'active' | 'passive';
  negotiations: string[];
  sharesAmount: number;
  euroAmount: number;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime: firebase.firestore.Timestamp;
  expireDateTime?: firebase.firestore.Timestamp;
  paymentDateTime?: firebase.firestore.Timestamp;
}

export interface BuyingBid extends DefaultBid {
  type: BidType.Buy;
  transactionUid?: string;
}

export interface SellingBid extends DefaultBid {
  type: BidType.Sell;
  usedPayments: PaymentRelation[] | firebase.firestore.FieldValue;
}

export type Bid = BuyingBid | SellingBid;

export interface CreateBidInput {
  investorId: string;
  assetId: string;
  type: BidType;
  sharesAmount: number;
  euroAmount: number;
  mode: 'active' | 'passive';
  expireDateTime?: number;
}

export interface RelateBidsInput {
  buyingBid: BidIds;
  sellingBid: BidIds;
}

export const enum TransactionPaymentMethods {
  ideal = 'ideal',
  bcmc = 'bcmc',
  sepa = 'sepa',
  'paypal-pc' = 'paypal-pc',
  'creditcard' = 'creditcard',
  'sofort-banking' = 'sofort-banking',
  mybank = 'mybank',
  giropay = 'giropay',
  mandate = 'mandate',
  multi = 'multi',
}

export interface ReserveBidInput {
  bid: BidIds;
  investorId: string;
  paymentMethod?: TransactionPaymentMethods;
  lang?: string;
  autoComplete?: boolean;
  flow: 'request' | 'direct';
}

export interface TransferInput {
  originInvestorId: string;
  destinyInvestorId: string;
  assetId: string;
  sharesAmount: number;
  euroAmount: number;
  transferDate: number;
}

export interface NegotiateBidInput {
  bid: BidIds;
  investorId: string;
  lang?: string;
}
